import React from "react";
import './Check.css';

class CheckButton extends React.Component {
    render() {
        return this.props.checked ? (
            <svg className={`checkmark hover ${this.props.animateChecked ? "animate" : ""}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            
        ) : (
            <div className="unset">{this.props.before}</div>
        );
    }    
}
 
export default CheckButton;