//import logo from './logo.svg';
import './App.css';
import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
	Navigate
} from "react-router-dom";
import config from "../../config";
import { UserContext } from '../../context/UserContext';
import Home from '../home/Home';
import SearchResult from '../searchResult/SearchResult';
import Login from '../login/Login';
import Register from '../register/Register';
import Header from '../header/Header';
import Error from '../error/Error';
import ProfilePage from '../profile/Profile';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: this.getToken(),
			isLoggedIn: false,
			userData: {}
		}
		this.updateState = this.updateState.bind(this);
		this.validateToken = this.validateToken.bind(this);
	}

	async componentDidMount() {
		if(this.state.token) {
			await this.validateToken(this.state.token);
		}
	}

	updateState(state) {
		this.setState(state);
		if(state.token) localStorage.setItem("token", state.token);
	}

	async validateToken(token) {
		//Backend request to validate token
		const data = {
			token: token
		}
		const result = await new Promise((resolve) => {
			fetch(`${config.apiEndpoint}/account/validate`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			})
			.then(res => res.json())
			.then(json => resolve(json))
			.catch(err => console.error(err));
		});

		if(result.valid) {
			this.updateState({
				isLoggedIn: true,
				userData:{
					username:result.username
				}
			});
		}
	}

	getToken() {
		return localStorage.getItem('token') || ""
	}

    render() {
		console.log(this.state);
		return (
			<Router>
				<UserContext.Provider value={this.state}>
					<Header username={this.state.userData.username || ""}/>
					<>
						<Routes>
							<Route path="*" element={<Error/>}/>
							<Route path="/" element={<Home />}/>
							<Route path="/home" element={<Home />}/>
							<Route path="/search" element={<SearchPage />}/>
							<Route path="/profile" element={<ProfilePage />}/>
							<Route path="/login" element={
								this.state.isLoggedIn ? 
									<Navigate to="/home" /> :
									<Login updateState={this.updateState} />
							}/>
							<Route path="/register" element={
								this.state.isLoggedIn ? 
									<Navigate to="/home" /> :
									<Register updateState={this.updateState} />
							}/>
						</Routes>
					</>
				</UserContext.Provider>
			</Router>
		);
    }
}

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
  }
function SearchPage() {
	let query = useQuery();
	return (
		<SearchResult query={query.get("q")} page={query.get("p") || "1"}/>
	);
}

export default App;
