import React from "react";
import Loading from "../loading/Loading";
import "./ProfileContainer.css";

class ProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({isLoading: false});
    }

    render() {
        return this.state.isLoading ? (
            <div>
                <Loading />
            </div>
        ) :
        (
            <div className="profile-container-component">
                <div className="profile-image">
                    <div style={{backgroundImage:`url(${this.props.data.image})`}}className="profile-picture"></div>
                </div>
                <div className="profile-header">
                    <h1>{this.props.data.username.split('#')[0]}</h1>
                    <div className="mini-statistics">
                        <p><b>{this.props.data.watchtime}h</b> Watchtime</p>
                        <p><b>{this.props.data.likes}</b> Likes</p>
                    </div>
                    <h2>Work in Progress</h2>
                </div>
            </div>
        );
    }
}

export default ProfileContainer;