import React from "react";
import Loading from "../loading/Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import "./ListContainer.css";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({isLoading: false});
    }

    render() {
        return this.state.isLoading ? (
            <div>
                <Loading />
            </div>
        ) :
        (
            <div className="list-container-component">
                <div className="list-container-header">
                    <h2>{this.props.header}</h2>
                    <FontAwesomeIcon style={{paddingTop: "10px"}} icon={solid("thumbs-up")}/>
                </div>
                
                <div 
                    className="list-container-content"
                    style={{ "--size": "200px" }}    
                >
                    {this.props.data}
                </div>
            </div>
        );
    }
}

export default Profile;