import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from "react-router-dom";
import "./Searchbar.css"

class Searchbar extends React.Component {
    constructor(props) {
        super(props);
        this.searchbarRef = React.createRef();
        this.link = React.createRef();
        this.state = {
            searchString: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {

    }

    handleKeyPress(e) {
        if(e.key === 'Enter' || e._reactName === 'onClick') {
            this.link.current.click();
        }
    }

    handleChange(e) {
        this.setState({
            searchString: this.searchbarRef.current.value
        });
    }

    render() {
        return (
            <div className="searchbar-container">
                <Link 
                    to={"/search?q=" + this.state.searchString}
                    className="hidden" 
                    ref={this.link} 
                />
                <div className={this.state.searchString ? "" : "deactivated"}>
                    <FontAwesomeIcon 
                        icon={solid("magnifying-glass")} 
                        onClick={this.handleKeyPress}
                        style={this.state.searchString ? {} : {pointerEvents:"none"}}
                    />
                </div>
                
                <input 
                    type="text"
                    onChange={this.handleChange}
                    ref={this.searchbarRef}
                    onKeyPress={this.handleKeyPress}
                    placeholder="Search"
                />         
            </div>
        );
    }
}
 
export default Searchbar;