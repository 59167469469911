import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import "../login/Login.css"
import config from '../../config';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordCheck = this.passwordCheck.bind(this);
        this.passwordRef = React.createRef();
        this.repeatPasswordRef = React.createRef();
        this.passwordMatchError = React.createRef();
        this.passwordIsValid = false;
        this.emailIsValid = false;
        this.checkPasswordLength = this.passwordCheck.bind(this);
        this.checkEmailFormat = this.checkEmailFormat.bind(this);
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.checkPasswordLength();
        if(!this.passwordIsValid || !this.emailIsValid) return;
        const { username, email, password } = document.forms[0];
        if(!username.value || !password.value) return;
        const data = {
            username: username.value,
            email: email.value,
            password: password.value
        };
        //Fetch
        const result = await new Promise((resolve) => {
            fetch(`${config.apiEndpoint}/account/register`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(json => resolve(json))
        });
        console.log(result);
        this.props.updateState({
            token: result.token,
            isLoggedIn: true,
            userData:{
                username:result.username,
                watchlist:[{}]
            }
        });
    }

    checkEmailFormat() {
        this.emailIsValid = true;
    }

    checkPasswordLength() {
        if(this.passwordRef.current.value.length <= 5) {
            //password to short
            this.passwordIsValid = false;
            return;
        }
        this.passwordCheck();
    }

    passwordCheck() {        
        if(this.passwordRef.current.value !==  this.repeatPasswordRef.current.value) {
            this.passwordMatchError.current.classList.remove('hidden');
            return;
        }

        this.passwordMatchError.current.classList.add('hidden');
        this.passwordIsValid = true;
    }

    render() {
        return (
            <div className="form">
                <div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="input-container">
                            <div>
                                <FontAwesomeIcon icon={solid("user")} />
                                <p>Username</p>
                            </div>
                            <input type="text" name="username" autoComplete="username" required />
                        </div>
                        <div className="input-container">
                            <div>
                                <FontAwesomeIcon icon={solid("envelope")} />
                                <p>Email</p>
                            </div>
                            <input onChange={this.checkEmailFormat} type="email" name="email" autoComplete="email" required />
                        </div>
                        <div className="input-container">
                            <div>
                                <FontAwesomeIcon icon={solid("lock")} />
                                <p>Password</p>
                            </div>
                            <input ref={this.passwordRef} onChange={this.checkPasswordLength} type="password" name="password" autoComplete="current-password" required />
                        </div>
                        <div className="input-container">
                            <div>
                                <FontAwesomeIcon icon={solid("lock")} />
                                <p>Repeat Password</p>
                            </div>
                            <input ref={this.repeatPasswordRef} onChange={this.passwordCheck} type="password" name="repeat-password" autoComplete="current-password" required />
                            <p style={{textAlign: 'left'}} ref={this.passwordMatchError} className="hidden error">Password does not match!</p>
                        </div>
                        <div className="button-container">
                            <input type="submit" />
                        </div>
                    </form>
                    <Link to="/login">
                        Already have an account? Log in.
                    </Link>
                </div>
            </div>
        );
    }    
}
 
export default RegisterPage;