import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CheckButton from '../buttons/Check';
import config from '../../config';
import "./ShowContainer.css"

class ShowContainer extends React.Component {
    constructor(props) {
        super(props);
        this.showContainerClick = this.showContainerClick.bind(this);
        this.addShowToList = this.addShowToList.bind(this);
        this.state = {
            checked: this.props.checked || false,
            animateChecked: !this.props.checked
        }
    }

    imageLoadError(e) {
        e.target.src = `https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-38-picture-grey-c2ebdbb057f2a7614185931650f8cee23fa137b93812ccb132b9df511df1cfac.svg`;
    }

    async showContainerClick(e) {
        this.addShowToList();
    }

    async addShowToList() {
        const data = {
            type: this.props.data.type,
            id: String(this.props.data.id),
            token: localStorage.getItem('token')
        };
        await fetch(`${config.apiEndpoint}/viewlist/put.content`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        this.setState({
            checked: true
        })
    }

    render() {
        return (
            <div 
                className="show-container" 
                onClick={this.state.checked ? ()=>{} : this.showContainerClick}
                style={{width: `${this.props.width || "300"}px`}}
            >
                {this.props.inList === true ? "" : <CheckButton
                    before={
                        <FontAwesomeIcon 
                            icon={solid("plus")}
                            className="hover"
                        />
                    }
                    checked={this.state.checked}
                    animateChecked={this.state.animateChecked}
                />}
                <div>
                    <img 
                        src={this.props.data.img} 
                        width={this.props.width || "300"} alt="Missing" 
                        loading="lazy" 
                        onError={this.imageLoadError}
                    />
                    <p>{this.props.data.name}</p>
                </div>
                
            </div>
        );
    }    
}
 
export default ShowContainer;