import React from "react";
import "./GridContainer.css"

class GridContainer extends React.Component {
    render() {
        return (
            <div className="grid-container">
                {this.props.data}
            </div>
        );
    }    
}
 
export default GridContainer;