import React from "react";
import Loading from "../loading/Loading";
import { UserContext } from "../../context/UserContext";
import config from "../../config";
import ProfileContainer from "./ProfileContainer";
import ListContainer from "./ListContainer";
import ShowContainer from "../showContainer/ShowContainer";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listData: {
                data:[],
                header:"Watched Shows/Movies"
            },
            userData: {
                username:"Felix#1234",
                watchtime: 200,
                likes: 12,
                image: "https://p.kindpng.com/picc/s/364-3640737_avatar-snapchat-hd-png-download.png"
            }
        }
    }

    async componentDidMount() {
        const list = await fetch(`${config.apiEndpoint}/viewlist/get.list`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({token:this.context.token})
        }).then(res=>res.json()).then(json => {
            console.log(json);
            return json;
        });

        console.log(list);

        for(const show in list) {
            const data = await fetch(`https://api.themoviedb.org/3/${list[show].type}/${show}?api_key=${config.apiKey}`)
            .then(res => res.json());
            console.log(data);
            const image_url = data.poster_path == null ? 
            `https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-38-picture-grey-c2ebdbb057f2a7614185931650f8cee23fa137b93812ccb132b9df511df1cfac.svg` :
            `https://image.tmdb.org/t/p/w440_and_h660_face${data.poster_path}`
            this.setState({
                listData: {
                    data: [
                        ...this.state.listData.data,
                        <ShowContainer 
                            key={data.id}
                            data={{
                                img: image_url,
                                name: data.title || data.name,
                                id: data.id,
                                type: {show}
                            }}
                            checked={true}
                            inList={true}
                            width="200"
                        />
                    ],
                    header: "Watched Shows/Movies"
                }
            })
        }
        this.setState({isLoading: false});
    }

    render() {
        return this.state.isLoading ? (
            <div>
                <Loading />
            </div>
        ) :
        (
            <div>
                <ProfileContainer data={this.state.userData} />
                <ListContainer
                    header={this.state.listData.header}
                    data={this.state.listData.data}
                />
            </div>
        );
    }
}

Profile.contextType = UserContext;
export default Profile;