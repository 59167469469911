import React from 'react';
import config from './config';
import ShowContainer from "./components/showContainer/ShowContainer";
export default async (response) => {
    const token = localStorage.getItem('token');
    let result = [];
    if(token) 
        result = await fetch(`${config.apiEndpoint}/viewlist/get.added`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({token:localStorage.getItem('token')})
        }).then(res=>res.json());
        
    return response.results.map((entry/*, index, {length}*/) => {
        let check = false;
        if(Object.keys(result).length !== 0) 
            check = result.indexOf(entry.id) === -1 ? false : true;
        const image_url = entry.poster_path == null ? 
        `https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-38-picture-grey-c2ebdbb057f2a7614185931650f8cee23fa137b93812ccb132b9df511df1cfac.svg` :
        `https://image.tmdb.org/t/p/w440_and_h660_face${entry.poster_path}`
        return <ShowContainer 
            key={entry.id}
            data={{
                img: image_url,
                name: entry.title || entry.name,
                id: entry.id,
                type: entry.media_type || "tv"
            }}
            checked={check}
        />
    })
}