import React from "react";
 
class Error extends React.Component {
    render() {
        return (
            <div>
                <h2>Ein Fehler ist aufgetreten</h2>
            </div>
        );
    }
}
 
export default Error;