import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import config from '../../config';
import "./Login.css";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.link = React.createRef();
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { email, password } = document.forms[0];
        const data = {
            email: email.value,
            password: password.value
        };
        console.log(data);
        //Fetch
        const result = await new Promise((resolve) => {
            fetch(`${config.apiEndpoint}/account/login`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(json => resolve(json))
        });
        console.log(result);
        this.props.updateState({
            token: result.token,
            isLoggedIn: true,
            userData:{
                username:result.username,
                watchlist:result.watchlist
            }
        });
        this.link.current.click();
    }

    render() {
        return (
            <div className="form">
                <Link 
                    to="/home"
                    className="hidden"
                    ref={this.link}
                />
                <div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="input-container">
                            <div>
                                <FontAwesomeIcon icon={solid("envelope")} />
                                <p>Email</p>
                            </div>
                            <input type="email" name="email" autoComplete="email" required />
                        </div>
                        <div className="input-container">
                            <div>
                                <FontAwesomeIcon icon={solid("lock")} />
                                <p>Password</p>
                            </div>
                            <input type="password" name="password" autoComplete="current-password" required />
                        </div>
                        <div className="button-container">
                            <input type="submit" />
                        </div>
                    </form>
                    <Link to="/register">
                        Don't have an account? Register now!
                    </Link>
                </div>
            </div>
        );
    }    
}

export default LoginPage;