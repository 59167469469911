import React from "react";
import Login from "../buttons/Login";
import Searchbar from "../searchbar/Searchbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import  { Link } from "react-router-dom";
import "./Header.css";
import { UserContext } from "../../context/UserContext";

class Header extends React.Component {
    render() {
        return (
            <header>
                <Link to="/home">
                    <FontAwesomeIcon 
                        icon={solid("house")}
                    />
                </Link>
                
                <Searchbar />
                <Login username={this.context.userData.username ? this.context.userData.username.split("#")[0] : ""}/>
            </header>
        );
    }
}
 
Header.contextType = UserContext;
export default Header;