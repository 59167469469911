import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from "react-router-dom";
import "./Button.css";

class Login extends React.Component {
    render() {
        return this.props.username ? (
            <Link to="/profile" className="profile-btn-container button-container">
                <FontAwesomeIcon icon={solid("user")} />
                <p>{this.props.username}</p>
            </Link>
        ) : (
            <Link to="/login" className="profile-btn-container button-container">
                <FontAwesomeIcon icon={solid("arrow-right-to-bracket")} />
                <p>Login</p>
            </Link>
        );
    }
}
 
export default Login;