import React from "react";
import GridContainer from "../gridContainer/GridContainer";
import Loading from "../loading/Loading";
import config from "../../config";
import getShowContainerMap from "../../GetShowContainerMap";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: []
        }
        this.loadedIds = [];
        this.page = 1;
        this.maxPage = 10;
        this.infiniteScrollLoading = false;
    }
    
    async addPageData(page) {
        const result = await fetch(`https://api.themoviedb.org/3/trending/tv/week?api_key=${config.apiKey}&page=${page}`).then(res=>res.json());
        result.results = result.results.filter((entry) => {
            if (this.loadedIds.indexOf(entry.id) === -1) {
                this.loadedIds.push(entry.id);
                return entry;
            }
            return [];
        })
        const containerMap = await getShowContainerMap(result);
        const data = this.state.data.concat(containerMap);
        this.setState({
            isLoading: false,
            data: data
        });
    }

    async componentDidMount() {
        await this.addPageData(this.page);
        window.addEventListener('scroll', async () => {
            if(this.page === this.maxPage) return;
            if(this.infiniteScrollLoading) return;
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

            if (scrollTop >= scrollHeight - (clientHeight * 2)) {
                //Load new Data
                this.infiniteScrollLoading = true;
                await this.addPageData(++this.page);
                this.infiniteScrollLoading = false;
            }
        });
    }

    render() {
        return this.isLoading ? (
            <>
                <Loading />
            </>
        ) :
        (
            <>
                <GridContainer data={this.state.data}/>
            </>
        );
    }
}
 
export default Home;