import React from "react";
import GridContainer from "../gridContainer/GridContainer";
import getShowContainerMap from "../../GetShowContainerMap";
import Loading from "../loading/Loading";
import {
	Navigate
} from "react-router-dom";
import config from "../../config";
import PageNavigator from "../pageNavigator/PageNavigator";

class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            data: [],
            maxPage: 1
        }
        this.loadPageData = this.loadPageData.bind(this);
    }

    async loadPageData(page = this.props.page) {
        //Fetch Data...
        const result = await fetch(`https://api.themoviedb.org/3/search/tv?api_key=${config.apiKey}&query=${this.props.query}&page=${page}`).then(res=>res.json());
        console.log(result);
        if(result.page > result.total_pages && result.total_pages !== 0) {
            await this.loadPageData(result.total_pages);
            return;
        }
        const data = await getShowContainerMap(result);
        this.setState({
            isLoaded: true,
            data: data,
            maxPage: result.total_pages
        });
    }

    async componentDidMount() {
        if(!this.props.query) return;
        await this.loadPageData();
    }

    async componentDidUpdate(prevProps) {
        if(prevProps.query !== this.props.query || prevProps.page !== this.props.page) {
            if(!this.props.query) return;
            await this.loadPageData();
        }
    }

    render() {
        if(!this.props.query) {
            return (
                <Navigate to="/home" />
            );
        }
        return this.state.isLoaded ? (
            <div>
                <GridContainer data={this.state.data}/>
                <PageNavigator max={this.state.maxPage} page={this.props.page} query={this.props.query} />
            </div>
        ) : (
            <div>
                <Loading />
            </div>
        );
    }
}
 
export default SearchResult;