import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './PageNavigator.css';

class PageNavigator extends React.Component {
    render() {
        return (
            <div className="page-navigator">
                {this.props.page > 1 ? 
                <Link to={`/search?q=${this.props.query}&p=${Number(this.props.page)-1}`}>
                    {<FontAwesomeIcon icon={solid("angle-left")} />}
                </Link> :
                <div className="deactivated">
                    <FontAwesomeIcon icon={solid("angle-left")} />
                </div>
                }
                <p>{this.props.page || 1}/{this.props.max}</p>
                {this.props.page < this.props.max ? 
                <Link to={`/search?q=${this.props.query}${`&p=${Number(this.props.page) + 1 || 2}`}`}>
                    {<FontAwesomeIcon icon={solid("angle-right")} />}
                </Link> :
                <div className="deactivated">
                    <FontAwesomeIcon icon={solid("angle-right")} />
                </div>
                }
            </div>
        )
    }
}
 
export default PageNavigator;